/* src/Navbar2.css */

.navbar {
    height: 20%;
    width: 100%;
    background-color: ghostwhite;
}

.logo {
    width: 120px;
    height: 70px;
    margin-left: 10px;
}

.tab {
    font-family: ROBOTO;
    color: rgb(109, 108, 108);
    font-size: 17px;
    margin-left: 10px;
    font-weight: bold;
    white-space: noWrap;
}

.tab:hover {
    color: rgb(0, 0, 0);
    font-size: 18px;
}

.tab:current{
    font-size: 25;
}

.navbar-nav {
    margin-left: auto;
} 

.button{
    margin-left: 10px;
    margin-right: 5px;
    font-size: 17px;
    font-family: ROBOTO;
    border-radius: 20px;
    height: 40px;
    width:100px !important;
}

.dropdwn{
    margin-left: 5px;
    margin-right: 10px;
    border: none;
    font-size: 17px;
    font-size: ROBOTO;
    color: black;
    height: 40px;
}

.dropdown-item:focus, .dropdown-item:hover{
    background-color: #9b9b9b !important; /* Change this to your desired color */
    color: white !important;
    font-family: ROBOTO;
}

.align-items-top{
    width: 70%;
    height: 45px;
}

.justify-content-between{
    width: 80%;
    justify-content: space-between;   
}

.navbar .icon{
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
}