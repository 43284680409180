.Login-body {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full viewport height */
    position: fixed;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-image: url('../Images/login_bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center; /* Vertical Center */
    justify-content: center; /* Horizontal Center */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}
