.swal-custom-button {
  width: 120px !important; 
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.swal-custom-button:hover,
.swal-custom-button:focus,
.swal-custom-button:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}


/* sweet alerts */
.Sweet-otp-area{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  width: 280px;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #1b1a1a;
  border-radius: 4px;
  margin-right: none;
}

.otp-input:last-child {
  margin-right: 0;
}

.custom-confirm-button {
  background-color: #333333
  ; /* Green */
  color: white;
  border: none;
  padding: 10px 5px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  width: 100px;
  margin-left: 25px;
}

.custom-confirm-button:hover {
  background-color: #000000;
}

.custom-cancel-button {
  background-color: #999999;
  color: white;
  border: none;
  width: 100px;
  padding: 10px 5px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 25px;
}

.custom-cancel-button:hover {
  background-color: #808080;
}

.resend{
  cursor: pointer;
}

.disabled-button {
  background-color: #cccccc; /* Light gray background */
  cursor: not-allowed; /* Change cursor to indicate non-interaction */
  pointer-events: none; /* Disable all pointer events */
  color: #666666; /* Dark gray text */
}
